import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from "../../components/seo";

const WhatHappens = () => {
    const seo = {
        metaDesc: 'What happens after the utility application is filed? - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="What happens after the utility application is filed? - Inventiv.org" canonical='/provisionalbuilder' seo={seo} />
            <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
                <div class="container">

                    <p><strong>Table of Contents</strong></p>
                    <p><strong>1. <a href="#after" class="d-clr">What happens after the utility application is filed?</a></strong></p>
                    <p style={{ paddingLeft: 40 + 'px' }}><strong>1.1&nbsp;<a href="#preexamination" class="d-clr">How to Respond to Formality/Pre-Examination Review?</a></strong></p>
                    <h1 id="after">1. What happens after the utility application is filed?</h1>
                    <img src="../assets/img/banner/30.jpg" width="100%" alt="" />
                    <table class="table table-bordered mt-3">
                        <tbody>
                            <tr>
                                <td width="623">
                                    <ul>
                                        <li>Upon filing the non-provisional patent application, the Patent Office reviews your patent application for formality before it is assigned over to an examiner.</li>
                                        <li>Patent applications are examined based on the order they are submitted, but some applications can be pulled out of line for priority examination if an expedited examination request is filed.</li>
                                        <li>The Examiner conducts an independent prior art search and gives an official action that stipulates whether your invention is allowed or rejected.</li>
                                        <li>If the Examiner allows the patent application to go to issuance, then you will have to pay an issue maintenance fee.&nbsp; At the 3 1/2 year, 7 1/2 year and 11 1/2 year mark after issuance.</li>
                                        <li>If the Examiner maintains the rejection, then you will have two options – an appeal or continue presenting arguments before the same Examiner.</li>
                                        <li>The decision may be based on the relevance of any new prior art references and the quality of the analysis presented in the second Office Action.</li>
                                        <li>Most second Office Actions are final.</li>
                                        <li>If you choose to file an appeal, then you can expect to wait for the Board of Patent Appeals and Interferences to render a decision on your case.</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>&nbsp;</p>
                    <p>The initial steps that the USPTO takes are similar to what it does for the PPA.&nbsp; Here’s a brief overview of the process:</p>
                    <ol>
                        <li>The USPTO receives your application and reviews for informality in the application and the fees paid.</li>
                        <li>It sends you a filing receipt. If you send it via regular mail, this is mailed to you if everything is in order. If you file electronically, a print-out that acknowledges receipt is available after you hit the “submit” button.&nbsp; &nbsp;The filing receipt includes information such as the inventor(s)’s names, invention’s title, which patent examining group will analyze your application, filing date, application’s serial number, and the number of claims you are asserting.&nbsp; If there is anything incorrect, you have limited time to request correction.</li>
                        <li>For a utility patent application, a patent examiner, will also look at the claims in the application. He/she will determine whether you qualify for a patent and if you do, what its scope should be. If the patent examiner rejects any claims, you and your lawyer should look at the reasoning behind the rejection and submit arguments countering the rejection and possibly submit an amendment that is congruent with your arguments against the rejection.</li>
                        <li>The patent examiner s the amendment, and either changes it to a Notice of Allowance, which is a patent application approval, or issues a final rejection of the claim.</li>
                        <li>If your case is allowed, you need to fill out the issuance form and pay the issue fee.</li>
                        <li>If your case is finally rejected, you can file an appeal, pay a request for continued examination (RCE) to keep on arguing, or abandon the case.</li>
                    </ol>
                    <img src="../assets/img/banner/31.jpg" width="100%" alt="" />
                    <p>Source: USPTO.GOV – Process for Obtaining a Utility Patent</p>

                    <h2 id="preexamination">1.1 How to Respond to Formality/Pre-Examination Review?</h2>
                    <p>When the PTO receives your application, they check to ensure that each application is complete and in condition for examination. A Notice of Missing Parts can be sent out if your application has missing drawings, no claim, or no filing fees.&nbsp; You will be notified of any deficiencies or informalities in new applications through various types of pre-examination notices which in turn require the submission of missing items or correction of the application within a time period for reply (typically one or two months) during which all the requirements must be satisfied. &nbsp;Here is an example:</p>
                    <img src="../assets/img/banner/32.png" width="100%" alt="" />
                    <p>In this case, the drawings need to be replaced so note that the replacement sheets are annotated on top with (REPLACEMENT SHEET). There should be no new matter and the response needs to state so or the replacement drawing and/or the replacement specification will not be accepted.</p>
                    <img src="../assets/img/banner/33.png" width="100%" alt="" />
                    <p>If there are issues with the Power of Attorney, a Notice Regarding Power of Attorney will indicate the specific reason why the power of attorney cannot be accepted. &nbsp;If there are issues with your reply, the PTO can send a Notification of Defective Reply with reasons to help you identify the problem and correct the reply.</p>

                </div>

            </section>

        </Layout>
    )
};

export default WhatHappens;